import React, { useState } from 'react'
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-intl'
import Language from './language'
import deBoat from '../images/boat-de.png'
import enBoat from '../images/boat-en.png'
import esBoat from '../images/boat-es.png'

const languageName = {
  es: 'Español',
  en: 'English',
  de: 'Deutsch',
}

const boatMapper = {
  es: esBoat,
  en: enBoat,
  de: deBoat,
}

const Languages = () => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  const onLanguageClick = (current, language) => {
    if (current !== language) {
      changeLocale(language)
    }
    setIsCollapsed(!isCollapsed)
  }

  const renderLangs = (languages, current) =>
    languages.map(language => {
      return (
        <Language
          key={language}
          isShown={isCollapsed}
          isActive={current === language}
          name={languageName[language]}
          boat={boatMapper[language]}
          onClick={() => onLanguageClick(current, language)}
        />
      )
    })

  const collapsedClass = isCollapsed ? 'lang-ribbons--collapsed' : ''

  return (
    <div className={`lang-ribbons ${collapsedClass}`}>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) => (
          <div className="flex lg:flex-col justify-center">
            {renderLangs(languages, currentLocale)}
          </div>
        )}
      </IntlContextConsumer>
    </div>
  )
}

export default Languages
