import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Link, useIntl } from 'gatsby-plugin-intl'

const sectionClasses = 'mt-4 md:pr-8 w-1/2 md:w-1/4 break-words'
const sectionTitleClasses = 'text-yellow-light font-semibold'
const sectionListClasses = 'mt-3 text-white leading-snug'
const sectionListItemLinkClasses =
  'inline-block mt-2 hover:text-yellow-light transition-all'

const Footer = () => {
  const { formatMessage } = useIntl()

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <footer className="bg-purple">
      <div className="custom-container custom-container--extralarge text-left py-12">
        <div className="flex justify-between items-center flex-wrap md:flex-no-wrap">
          <div className="w-full md:w-auto flex-grow flex flex-wrap">
            {/* Content */}
            <div className={sectionClasses}>
              <h4 className={sectionTitleClasses}>
                {formatMessage({ id: `footer.content.title` })}
              </h4>

              <ul className={sectionListClasses}>
                <li>
                  <Link className={sectionListItemLinkClasses} to="/school">
                    {formatMessage({ id: `footer.content.items.school` })}
                  </Link>
                </li>

                <li>
                  <Link className={sectionListItemLinkClasses} to="/student">
                    {formatMessage({ id: `footer.content.items.student` })}
                  </Link>
                </li>

                <li>
                  <Link className={sectionListItemLinkClasses} to="/teacher">
                    {formatMessage({ id: `footer.content.items.teacher` })}
                  </Link>
                </li>

                <li>
                  <Link className={sectionListItemLinkClasses} to="/tutor">
                    {formatMessage({ id: `footer.content.items.tutor` })}
                  </Link>
                </li>
              </ul>
            </div>

            {/* Help */}
            <div className={sectionClasses}>
              <h4 className={sectionTitleClasses}>
                {formatMessage({ id: `footer.help.title` })}
              </h4>

              <ul className={sectionListClasses}>
                <li>
                  <Link
                    className={sectionListItemLinkClasses}
                    to={`/#${formatMessage({ id: 'sections.contact.id' })}`}
                  >
                    {formatMessage({ id: `footer.help.items.contact` })}
                  </Link>
                </li>

                <li>
                  <Link
                    className={sectionListItemLinkClasses}
                    to="/technical-support"
                  >
                    {formatMessage({
                      id: `footer.help.items.technicalSupport`,
                    })}
                  </Link>
                </li>

                <li>
                  <Link
                    className={sectionListItemLinkClasses}
                    to="/privacy-policy"
                  >
                    {formatMessage({ id: `footer.help.items.privacyPolicy` })}
                  </Link>
                </li>

                <li>
                  <Link
                    className={sectionListItemLinkClasses}
                    to="/terms-of-use"
                  >
                    {formatMessage({ id: `footer.help.items.termsOfUse` })}
                  </Link>
                </li>
              </ul>
            </div>

            {/* Company */}
            <div className={sectionClasses}>
              <h4 className={sectionTitleClasses}>
                {formatMessage({ id: `footer.company.title` })}
              </h4>

              <ul className={sectionListClasses}>
                <li>
                  <Link className={sectionListItemLinkClasses} to="/downloads">
                    {formatMessage({ id: `footer.company.items.downloads` })}
                  </Link>
                </li>

                <li>
                  <a
                    className={sectionListItemLinkClasses}
                    rel="noopener noreferrer"
                    target="_blank"
                    href="/press"
                  >
                    {formatMessage({ id: `footer.company.items.press` })}
                  </a>
                </li>

                <li>
                  <Link className={sectionListItemLinkClasses} to="/about-us">
                    {formatMessage({ id: `footer.company.items.about` })}
                  </Link>
                </li>
              </ul>
            </div>

            {/* Comunity */}
            <div className={sectionClasses}>
              <h4 className={sectionTitleClasses}>
                {formatMessage({ id: `footer.community.title` })}
              </h4>

              <ul className={sectionListClasses}>
                <li>
                  <a
                    className={sectionListItemLinkClasses}
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.facebook.com/pages/category/Education/Vikidz-412648239469880/"
                  >
                    Facebook
                  </a>
                </li>

                <li>
                  <a
                    className={sectionListItemLinkClasses}
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.youtube.com/channel/UC1vGG9ueCEIvlxtmHmIHSOQ"
                  >
                    YouTube
                  </a>
                </li>

                <li>
                  <a
                    className={sectionListItemLinkClasses}
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.linkedin.com/company/vikidz/"
                  >
                    LinkedIn
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="w-56 mt-8 md:mt-0 mx-auto md:mx-0">
            <Link to="/">
              <Img
                alt="Logo"
                className="animation-pulse"
                fluid={data.logo.childImageSharp.fluid}
              />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
