import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Header from './header'
import Footer from './footer'
// import StartModal from './start-modal'
import './layout.css'
import favicon from '../images/favicon.ico'

const Layout = ({ children, headerLinkColor, isRetoPage, isStartPage }) => {
  return (
    <>
      <Helmet>
        <link href={favicon} rel="shortcut icon" type="image/png" />
      </Helmet>

      <Header linkColor={headerLinkColor} isRetoPage={isRetoPage} />

      <main>{children}</main>

      <Footer />

      {/* <StartModal isStartPage={isStartPage} /> */}
    </>
  )
}

Layout.defaultProps = {
  headerLinkColor: 'lg:text-white',
  isRetoPage: false,
  isStartPage: false,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
