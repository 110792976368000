import React from 'react'
import PropTypes from 'prop-types'

const Language = ({ isActive, name, boat, onClick }) => {
  const activeClass = isActive ? 'lang-ribbon--active' : ''

  return (
    <button className={`lang-ribbon ${activeClass}`} onClick={onClick}>
      <div className="flex flex-col items-center mt-0 lg:-mt-4">
        <img className="w-8 lg:w-16" src={boat} alt="Boat" />
        <span>{name}</span>
      </div>
    </button>
  )
}

Language.propTypes = {
  isActive: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  boat: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Language
