import React, { useState } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import PropTypes from 'prop-types'
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl'
import Languages from './languages'
import logo from '../images/logo.png'
// import challenge from '../images/challenge/logo.png'
import challenge from '../images/torneo2021/2021_VikidzLogo.png'

const sections = ['experience', 'benefits', 'testimonials', 'contact']

const Header = ({ linkColor, isRetoPage }) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const { formatMessage, locale } = useIntl()

  const isLocaleEs = locale === 'es'
  const menuClass = isCollapsed ? 'block' : 'hidden'

  const renderHrefs = () =>
    sections.map(section => {
      const id = formatMessage({ id: `sections.${section}.id` })
      return (
        <li
          key={section}
          className={`w-full lg:w-1/4 ${
            isLocaleEs ? 'px-2' : 'px-4'
          } py-2 lg:py-4 text-sm text-white ${linkColor} font-semibold uppercase tracking-widest`}
        >
          <Link to={`/#${id}`} onClick={() => setIsCollapsed(false)}>
            <FormattedMessage id={`sections.${section}.title`} />
          </Link>
        </li>
      )
    })

  return (
    <header>
      <nav className="fixed lg:absolute w-full bg-purple lg:bg-transparent shadow-lg lg:shadow-none z-50">
        <div className="custom-container custom-container--extralarge">
          <div className="flex items-center lg:items-start justify-between flex-wrap">
            <div className="lg:w-1/5 py-2 flex-shrink-0">
              <div className="lg:relative">
                <div className="lg:absolute">
                  <Link to="/">
                    <img
                      className="h-16 lg:h-20"
                      src={logo}
                      alt="Vikidz logo"
                    />
                  </Link>
                </div>
              </div>
            </div>

            <div className="block lg:hidden">
              <button
                className="p-2 border-2 border-white text-white rounded-full"
                onClick={() => setIsCollapsed(!isCollapsed)}
              >
                <svg
                  className="fill-current h-3 w-3"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </button>
            </div>

            <div
              className={`w-full ${menuClass} flex-grow lg:w-auto lg:flex lg:justify-between flex-col lg:flex-row`}
            >
              <ul className="lg:w-5/6 py-4 flex lg:items-center justify-around flex-col lg:flex-row">
                {renderHrefs()}
              </ul>

              <div
                className={`${
                  isLocaleEs && !isRetoPage ? 'block' : 'hidden'
                } w-24 lg:w-32 p-2 mx-auto lg:mx-0`}
              >
                <GatsbyLink to="/es/torneo">
                  <img
                    alt="Reto Vikidz 2020"
                    className=""
                    // className="animation-pulse"
                    src={challenge}
                  />
                </GatsbyLink>
              </div>

              <div className="lg:w-1/6 lg:relative">
                <div className="lg:absolute lg:right-0">
                  <Languages />
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  linkColor: PropTypes.string.isRequired,
  isRetoPage: PropTypes.bool.isRequired,
}

export default Header
